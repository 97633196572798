<template>
    <v-col class="my-0 py-0 px-0 px-md-8 pb-15">
        <v-col class="text-start mt-5 mb-3">
            <v-alert text dense color="#8061c2" border="left" class="pl-5">
                <h1 class="title mt-1">Historial de retiros</h1>
                <p class="desc mt-0 mb-1">A continuación, se muestra el historial de retiros realizadas por los
                    usuarios.</p>
            </v-alert>
        </v-col>

        <!-- Search Bar -->
        <v-row class="mx-3">
            <v-col  cols="12" md="4" class="px-0">
                <v-text-field v-model="search" color="#8061c2" label="Buscar retiros" append-icon="mdi-magnify"
                    single-line hide-details outlined class="mb-2 mx-0 px-0" @input="fetchWithdrawals"></v-text-field>
            </v-col>

            <v-col cols="12" md="4" class="px-md-2 px-0">
                <v-select v-model="filter" :items="filterOptions" label="Filtrar por" outlined hide-details></v-select>
            </v-col>

            <v-col cols="12" md="4" class="px-0 ">
                <v-select v-model="order" :items="orderOptions" label="Ordenar" outlined hide-details></v-select>
            </v-col>
        </v-row>


        <v-data-table :headers="headers" :items="withdrawals" :loading="loading" class="elevation-1 mx-3 px-2 py-4"
            :items-per-page="perPage" :server-items-length="totalWithdrawals" :page.sync="page" :footer-props="{
                itemsPerPageOptions: [50, 100, 200, 300, -1],
                showFirstLastPage: true
            }" @update:page="fetchWithdrawals" @update:items-per-page="changePerPage">
            <template v-slot:item.user_logo_path="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-avatar size="40" class="clickable-avatar" @click="showLogo(item.user_logo_path)"
                            @mouseover="hoveredLogo = item.user_logo_path" @mouseleave="hoveredLogo = null"
                            v-bind="attrs" v-on="on">
                            <v-img :src="item.user_logo_path" v-if="item.user_logo_path"></v-img>
                            <v-avatar v-else color="black">
                                <span class="white--text">{{ item.user_first_name.charAt(0) }}{{
                                    item.user_last_name.charAt(0) }}</span>
                            </v-avatar>
                        </v-avatar>
                    </template>
                    <span>Ver Imagen</span>
                </v-tooltip>
            </template>

            <template v-slot:item.status_withdrawal_name="{ item }">
                <v-chip :color="getStatusColor(item.status_withdrawal_name)" dark small>
                    {{ item.status_withdrawal_name }}
                </v-chip>
            </template>



            <template v-slot:item.amount="{ item }">
                $ {{ item.amount }} USD
            </template>

            <template v-slot:item.payment_method="{ item }">
                {{ item.payment_method === 'bank' ? 'Cuenta de banco' : '' }}
                {{ item.payment_method === 'crypto' ? 'Criptomoneda' : '' }}
                {{ item.payment_method === 'cosmo' ? 'Cosmo' : '' }}
                {{ item.payment_method === 'paypal' ? 'PayPal' : '' }}
            </template>


            <template v-slot:item.details_metodo="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon color="rgb(128 97 194)" v-bind="attrs" v-on="on" @click="showDetails(item)">
                            <v-icon>mdi-information</v-icon>
                        </v-btn>
                    </template>
                    <span>Ver Detalles</span>
                </v-tooltip>
            </template>

        </v-data-table>

        <!-- Modal Informacion del metodo de pago -->
        <ModalInfoPay  :dialogDetails.sync="dialogDetails" :withdrawalMethod="withdrawalMethod" :paymentDetails="paymentDetails"
            @close="dialogDetails = false"></ModalInfoPay>



        <!-- Modal para ampliar el logo -->
        <v-dialog v-model="dialog" max-width="14rem" class="rounded-lg">
            <v-img :src="selectedLogo" width="14rem"></v-img>
        </v-dialog>
    </v-col>
</template>

<script>
import axios from '@/axios.js';
import ModalInfoPay from '@/components/partials/admin/ModalInfoPay.vue';

export default {
    components: {
        ModalInfoPay
    },
    data() {
        return {
            roles: [],
            search: '',
            loading: true,
            saveLoading: false,
            selectedLogo: null,
            show1: false,
            page: 1,
            perPage: 50,
            withdrawals: [],
            totalWithdrawals: 0,
            dialog: false,
            dialogDetails: false,
            withdrawalMethod: null,
            paymentDetails: {},
            filter: 'created_at',
             order: 'asc',
            headers: [
                { text: 'Avatar', value: 'user_logo_path', align: 'start', sortable: false },
                { text: 'Email', value: 'user_email' },
                { text: 'Monto', value: 'amount' },
                { text: 'Metodo de pago', value: 'payment_method' },
                { text: 'Estado', value: 'status_withdrawal_name' },
                { text: 'Detalles de metodo de pago', value: 'details_metodo', sortable: false, align: 'center', },
                { text: 'Fecha de retiro', value: 'created_at' },
                // { text: 'Opciones', value: 'actions', sortable: false }
            ],
            filterOptions: [
                {
                    text: 'Fecha Creación',
                    value: 'created_at'
                },
                {
                    text: 'Monto',
                    value: 'amount'
                },
    
            ],
            orderOptions: [
                {
                    text: 'Mayor',
                    value: 'desc'
                },
                {
                    text: 'Menor',
                    value: 'asc'
                }
            ],
            rules: {
                required: value => !!value || this.$t('navbar.required'),
                min: v => v.length >= 8 || this.$t('navbar.min'),
                minPhone: v => v.length >= 6 || this.$t('navbar.min-phone'),
                maxPhone: v => v.length <= 12 || this.$t('navbar.max-phone'),
                numericPhone: v => /^[0-9]+$/.test(v) || this.$t('navbar.numeric-phone'),
                alpha: v => /^[a-zA-ZÑñ ]*$/.test(v) || this.$t('navbar.alpha'),
                minName: v => v.length >= 3 || this.$t('navbar.min-name'),
                maxName: v => v.length <= 30 || this.$t('navbar.max-name'),
                email: value => {
                    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
                    return pattern.test(value) || this.$t('navbar.invalid-email')
                }
            },
        };
    },


    created() {
        this.fetchWithdrawals();
    },

    methods: {
        showLogo(logoPath) {
            this.selectedLogo = logoPath ? logoPath : require('../../assets/illustrations/no-photo.jpg');
            this.dialog = true;
        },

        getLabel(key) {
            const labels = {
                country: 'País',
                type_document: 'Tipo de documento',
                document_number: 'Número de documento',
                type_account: 'Tipo de cuenta',
                bank: 'Banco',
                account_number: 'Número de cuenta'
            }
            return (labels[key] || key).toUpperCase();
        },
        getIcon(key) {
            const icons = {
                country: 'mdi-flag',
                type_document: 'mdi-card-account-details',
                document_number: 'mdi-identifier',
                type_account: 'mdi-piggy-bank',
                bank: 'mdi-bank',
                account_number: 'mdi-numeric'
            }
            return icons[key] || 'mdi-information'
        },

        showDetails(item) {
            this.withdrawalMethod = item.payment_method;
            this.paymentDetails = item.payment_details || {};
            this.dialogDetails = true;
        },

        async fetchWithdrawals() {
            this.loading = true;
            try {
                const response = await axios.get('/admin/get-withdrawals', {
                    params: {
                        page: this.page,
                        per_page: this.perPage,
                        search: this.search,
                        list: 'completed_withdrawals',
                        order: this.order,
                        filter: this.filter
                    }
                });
                this.withdrawals = response.data.withdrawals.data;
                this.totalWithdrawals = response.data.total;
            } catch (error) {
                console.error(error);
            } finally {
                this.loading = false;
            }
        },



        changePerPage(newPerPage) {
            this.perPage = newPerPage;
            this.page = 1; // Reiniciar a la primera página al cambiar los elementos por página
            this.fetchWithdrawals();
        },

        getStatusColor(statusName) {
            if (statusName.toLowerCase() === 'solicitado') {
                return '#f29339';
            } else if (statusName.toLowerCase() === 'en proceso') {
                return '#8e84c0';
            } else if (statusName.toLowerCase() === 'pagado') {
                return '#2e8b57';
            } else if (statusName.toLowerCase() === 'rechazado') {
                return '#f44336';
            }
        },
    },

    watch: {
        order(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.fetchWithdrawals();
            }
        },
        filter(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.fetchWithdrawals();
            }
        }
    },
};
</script>

<style scoped>
.clickable-avatar:hover {
    transform: scale(1.2);
    transition: transform 0.3s ease;
}
</style>
